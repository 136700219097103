import React, { useEffect } from "react";
import Home from "./Home";
import Collection from "./Collection";
import Experience from "./Experience";
import Footer from "./Footer";
import About from "./About";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Register from "./Register";
import HeroHome from "./HeroHome";
import Section from "./Section";
// eslint-disable-next-line no-unused-vars
import { Route, Link, Routes } from "react-router-dom";
import profil from "./pic/new.jpg";
import collect from "./pic/collect.jpg";

// eslint-disable-next-line
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Home img={profil} />
              <Collection />
              <Experience />
              <About />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/Collection"
          element={
            <>
              <Hero img={collect} />
              <Collection />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/Experience"
          element={
            <>
              <Home img={profil} />
              <Experience />
              <About />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/About"
          element={
            <>
              <Home img={profil} />
              <About />
              <Experience />
              <Collection />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/Promotion"
          element={
            <>
              <Navbar />
              <HeroHome />
              <Section />
              <Register />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
