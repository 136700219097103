/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { DocumentTextIcon, MoonIcon, SunIcon, CalendarIcon } from "@heroicons/react/outline";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import gambar from "./pic/ms-icon-512x310.png";
import white from "./pic/white-icon-512x310.png";
import { useState } from "react";
import { useEffect } from "react";
import "aos/dist/aos.css";

const navigation = [
  { name: "Home", href: "#", link: "/" },
  { name: "Collection", href: "#Collection", link: "/Collection" },
  { name: "Experience", href: "#Experience", link: "/Experience" },
  { name: "About", href: "#About", link: "/About" },
];

const startYear = 2020;
const currentYear = new Date().getFullYear();
const yearsExperience = currentYear - startYear;

// eslint-disable-next-line
// https://cal.com/ariki/CS 
function Home(image) {
  const [Theme, setTheme] = useState(null);
  useEffect(() => {
    if(window.matchMedia('(prefers-color-scheme: dark)').matches){
      setTheme("dark");
    }else{
      setTheme("light");
    }
  },[]);
  useEffect(() => {
    if (Theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [Theme]);
  
  const handleswitch = () => {
    setTheme(Theme === "dark" ? "light" : "dark");
  };
  return (
    <div className="relative overflow-hidden transition-all duration-500 ease-in-out bg-white bg-cover dark:bg-black">
      <div className="mx-auto max-w-6xl">
        <div className="relative z-10 pb-8 bg-white dark:bg-black transition-all duration-500 ease-in-out sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
  className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block dark:text-black transition-all duration-500 ease-in-out"
  fill="currentColor"
  viewBox="0 0 100 100"
  preserveAspectRatio="none"
  aria-hidden="true"
>
  <path d="M 0 0 Q 50 50, 0 100 L 100 100 Q 50 50, 100 0 Z" />
</svg>


          <Popover>
            <div className="relative top-0 z-40 inline-block px-4 pt-6 sm:px-6 lg:px-8 lg:left-16 md:left-36">
              <nav
                className="fixed flex items-center justify-between py-6 transition-all duration-1000 ease-in-out bg-indigo-600 shadow-md dark:bg-white dark:bg-opacity-20 rounded-xl shadow-black/50 to-transparent sm:h-10 lg:justify-start bg-opacity-80 backdrop-blur-sm"
                aria-label="Global"
              >
                <div className="hidden py-2 space-y-1 md:block md:ml-10 md:pr-4 md:space-x-8 ">
                  <img
                    className="inline-block m-0 cursor-pointer w-7 animate-pulse"
                    src={white}
                    alt="Logo"
                  />
                  {navigation.map((item) => (
                    <Link to={item.link} onClick={window.scrollTo(0, 0)}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium transition-all duration-300 ease-in-out text-gray-50 hover:text-indigo-300"
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </nav>
            </div>
            
            <div className="fixed z-10 block min-w-full p-2 ml-2 transition origin-top-right transform bottom-16 md:hidden">
              <div className="fixed max-w-md overflow-hidden bg-indigo-600 shadow-lg rounded-xl ring-1 ring-black ring-opacity-5 bg-opacity-80 backdrop-blur-sm shadow-black/50">
                <div className="px-2 pt-2 pb-2 space-y-1 divide-x">
                  {navigation.map((item) => (
                    <Link to={item.link} onClick={window.scrollTo(0, 0)}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="inline-flex px-3 py-2 text-base font-medium text-gray-100 "
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Popover>
          <div className="fixed block p-2 text-sm transition-all duration-500 ease-in-out bg-black rounded-lg shadow-lg w-13 h-13 text-slate-100 dark:bg-white dark:text-black top-2 left-2 sm:hidden">
            <button onClick={handleswitch}>
              <MoonIcon className="invisible hidden w-6 h-6 dark:visible dark:block" />
              
              <SunIcon className="visible block w-6 h-6 dark:invisible dark:hidden"/>
            </button>
          </div>
          <div className="fixed hidden p-2 text-sm transition-all duration-500 ease-in-out bg-black rounded-lg shadow-lg right-5 w-13 h-13 text-slate-100 bottom-5 dark:bg-white dark:text-black sm:block hover:bg-opacity-60 dark:hover:bg-gray-300">
            <button onClick={handleswitch}>
              <MoonIcon className="invisible hidden w-6 h-6 dark:visible dark:block" />
              
              <SunIcon className="visible block w-6 h-6 dark:invisible dark:hidden"/>
            </button>
          </div>
          <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center lg:text-left " data-aos="fade-right">
              <img
                className="inline-block w-8 md:hidden sm:visible animate-bounce"
                src={Theme === "light" ? gambar : white}
                alt="Logo"
              />
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
                <span className="block transition-all duration-200 ease-in-out cursor-pointer hover:underline hover:translate-x-10 dark:text-neutral-300">
                  ARIKI ANGGI
                </span>{" "}
                <span className="block text-xl text-indigo-600">
                  Computer Science Teacher
                </span>
              </h1>
              <p className="mt-3 text-base leading-none text-justify text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-l lg:mx-0">
              Currently as a Coding Instructor and Head Of The Board of {" "}
                <div className="relative z-0 inline-block before:block before:absolute before:-inset-0 before:-skew-y-3 before:bg-indigo-500">
                  <a
                    href="https://subkids.netlify.app/"
                    className="relative text-white transition-all duration-500 ease-in-out hover:font-bold"
                  >
                    
                    Subkids 
                  </a>
                </div>
                {" "} and IT Lecturer at {" "}
                <div className="relative z-0 inline-block before:block before:absolute before:-inset-0 before:-skew-y-3 before:bg-indigo-500">
                <a
                    href="https://www.usg.education/uic-college/"
                    className="relative text-white transition-all duration-500 ease-in-out hover:font-bold"
                >
                  UIC College 
                  </a>
                </div>
                . I am a dedicated Computer Science Teacher and have contributed to the education sector for the past {yearsExperience} years, also interested in competitive programming that applies algorithms using Python as the main language or C++, and Developing Web using HTML TailwindCSS with ReactJs.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow "> 
                  <a
                    href="https://cal.com/ariki/CS" 
                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white transition-all duration-500 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 dark:bg-white dark:bg-opacity-10 dark:hover:border-blue-700 md:py-4 md:text-lg md:px-10 dark:hover:bg-opacity-20"
                  >
                    <CalendarIcon className="w-5 h-5 mr-1"/>
                    Make Appointment
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="https://resume.io/r/aXiBC6FJm"
                    className="flex items-center justify-center w-full px-3 py-3 text-base font-medium text-indigo-700 transition-all duration-500 ease-in-out bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 dark:bg-white dark:bg-opacity-20 dark:text-neutral-300 dark:hover:bg-opacity-10 dark:hover:border-blue-700" 
                  >
                    <DocumentTextIcon className="w-5 h-5 mr-1"/>
                    My Resume
                  </a>
                  
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 " data-aos="zoom-out">
        <img
          className="object-cover object-right w-full h-56 transition-transform duration-1000 ease-in-out sm:h-72 md:h-96 lg:w-full lg:h-full hover:scale-150"
          src={image.img}
          alt=""
        />
      </div>
    </div>
  );
}

export default Home;
